import { CbhIcon, type IconType } from "@clipboard-health/ui-components";
// TODO: Use our own Chip
// eslint-disable-next-line no-restricted-imports
import { Chip } from "@mui/material";

interface EstimatedPayFormChipProps<T> {
  value: T;
  label: string;
  isSelected: boolean;
  icon?: IconType;
  onChange: (value: T) => void;
}

export function EstimatedPayFormChip<T extends string | number>(
  props: EstimatedPayFormChipProps<T>
) {
  const { value, label, isSelected, icon, onChange } = props;

  return (
    <Chip
      key={value}
      label={label}
      variant="outlined"
      color="default"
      icon={icon ? <CbhIcon type={icon} size="small" /> : undefined}
      sx={{
        "&.MuiChip-root": {
          border: (theme) =>
            `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.grey[100]}`,
          paddingY: 6,
          paddingX: 4,
          borderRadius: 6,
          backgroundColor: (theme) =>
            isSelected ? theme.palette.background.tertiary : theme.palette.grey[100],
          "&:hover": {
            backgroundColor: (theme) =>
              isSelected ? theme.palette.background.tertiary : theme.palette.grey[100],
          },
        },
      }}
      onClick={() => {
        onChange(value);
      }}
    />
  );
}
