import {
  BottomSheet,
  BottomSheetIllustrationContent,
  Card,
  CbhIcon,
} from "@clipboard-health/ui-components";
import { Image, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent, Stack } from "@mui/material";
import { isCbhApiAxiosError } from "@src/appV2/Errors/errorUtilts";
import { useToast } from "@src/appV2/lib/Notifications/Toasts/useToast";
import { useLocalStorage } from "@src/appV2/lib/utils";
import { differenceInHours } from "date-fns";

import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { useCreateChatWithRecruiter } from "../api/useCreateChatWithRecruiter";
import workerAvatarImage from "../assets/images/worker-avatar-sample.png";

interface ChatWithRecruiterCardProps {
  onCloseClicked?: () => void;
}

export function ChatWithRecruiterCard(props: ChatWithRecruiterCardProps) {
  const { showErrorToast } = useToast();
  const { onCloseClicked } = props;

  const { mutateAsync: createChatWithRecruiter } = useCreateChatWithRecruiter();

  const successModalState = useModalState();

  const [lastRequestDatetime, setLastRequestDatetime] = useLocalStorage<string | undefined>(
    "lastChatWithClipboardRecruiterRequestDatetime",
    undefined
  );

  const onChatNowClicked = async () => {
    if (
      !isDefined(lastRequestDatetime) ||
      // poor man's rate limiting
      differenceInHours(new Date(), new Date(lastRequestDatetime)) > 24
    ) {
      try {
        await createChatWithRecruiter();
        setLastRequestDatetime(new Date().toISOString());
      } catch (error) {
        if (isCbhApiAxiosError(error)) {
          showErrorToast(
            `Failed to create chat: ${error.response.data.errors
              .map((errorPart) => errorPart.detail)
              .join(", ")}`
          );
        } else {
          showErrorToast("Failed to create chat");
        }
      }
    }

    successModalState.openModal();
  };

  return (
    <>
      <Card
        sx={{
          py: 3,
          px: 4,
          background: "linear-gradient(110.99deg, #98CCFB 11.13%, #D0E5F8 46.93%)",
        }}
      >
        <CardContent sx={{ position: "relative" }}>
          <CbhIcon
            type="close"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              position: "absolute",
              top: 0,
              right: 0,
              m: 2,
            })}
            onClick={onCloseClicked}
          />
          <Stack spacing={5} alignItems="center" textAlign="center">
            <Image
              src={workerAvatarImage}
              alt="Recruiter Avatar"
              width={64}
              sx={(theme) => ({
                borderRadius: "50%",
                backgroundColor: theme.palette.background.paper,
              })}
            />

            <Stack px={9}>
              <Text semibold variant="h5">
                Chat with a Clipboard Hiring Recruiter
              </Text>
            </Stack>

            <Text light variant="body2" color="text.secondary">
              Chat with a Clipboard Health Hiring Recruiter who will help you get booked into
              interviews for jobs you&apos;re interested in.
            </Text>

            <Button invert variant="contained" size="small" onClick={onChatNowClicked}>
              Chat now
            </Button>
          </Stack>
        </CardContent>
      </Card>

      <BottomSheet
        modalState={successModalState}
        footer={
          <DialogFooter
            orientation="vertical"
            backgroundColor={(theme) => theme.palette.background.tertiary}
            onClose={() => {
              successModalState.closeModal();
            }}
          />
        }
      >
        <BottomSheetIllustrationContent
          illustrationType="chat"
          title="You’ll get a call back soon!"
          description="Your request has been sent to our team and a Clipboard Hiring Recruiter will be in touch via a phone call."
        />
      </BottomSheet>
    </>
  );
}
