import { BottomSheet, CbhIcon } from "@clipboard-health/ui-components";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
// TODO: Use our own Chip
import { DialogContent, Stack } from "@mui/material";
import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import pluralize from "pluralize";
import { type Control, Controller } from "react-hook-form";
import { z } from "zod";

import { DialogFooter } from "../../components/DialogFooter";
import { IosSwitch } from "../../components/IosSwitch";
import { shiftTypeOptions } from "../../PlacementCandidate/constants";
import { ShiftNameType } from "../../PlacementCandidate/types";
import { type PayRateMeta } from "../lib/useComputedPay";
import { type PayRate } from "../types/payRate";
import { EstimatedPayFormChip } from "./EstimatedPayFormChip";
import { EstimatedPayFormItem } from "./EstimatedPayFormItem";

export const estimatedPayFormSchema = z.object({
  yearsOfExperience: z.number().optional(),
  includeBenefits: z.boolean(),
  shiftType: z.union([z.nativeEnum(ShiftNameType), z.null()]),
  isWeekendShift: z.boolean(),
});

export type EstimatedPayFormData = z.infer<typeof estimatedPayFormSchema>;

interface EstimatedPayFormProps {
  control: Control<EstimatedPayFormData>;
  payRate: PayRate;
  payRateMeta: PayRateMeta;
  totalShiftTypePayDifferential: number;
}

export function EstimatedPayForm({
  control,
  payRate,
  payRateMeta,
  totalShiftTypePayDifferential,
}: EstimatedPayFormProps) {
  const benefitsInfoModalState = useModalState();

  const { userContributionCount, minYearsOfExperienceSet } = payRateMeta;

  const shiftTypeOptionsFiltered = shiftTypeOptions.filter(
    ({ value }) =>
      isDefined(payRate.shiftTypeDiff) &&
      isDefined(payRate.shiftTypeDiff[value]) &&
      payRate.shiftTypeDiff[value] > 0
  );

  const hasWeekendDiff = isDefined(payRate.weekendDiff) && payRate.weekendDiff > 0;

  return (
    <>
      <Stack spacing={4} data-testid="estimated-pay-form">
        <EstimatedPayFormItem title="Base rate">
          <Stack spacing={3}>
            <Text variant="subtitle1" sx={{ fontWeight: 500 }}>
              Experience
            </Text>
            <Text variant="body2" color={(theme) => theme.palette.grey[600]}>
              Select experience level to update base rate{" "}
              {userContributionCount > 0 &&
                `(based on ${userContributionCount} user ${pluralize(
                  "contribution",
                  userContributionCount
                )})`}
            </Text>
            <Stack direction="row" gap={2} flexWrap="wrap" paddingTop={2} paddingBottom={6}>
              <Controller
                name="yearsOfExperience"
                control={control}
                render={({ field }) => (
                  <>
                    {[...minYearsOfExperienceSet]
                      .sort((a, b) => a - b)
                      .slice(0, 4)
                      .map((value) => {
                        const isSelected = field.value === value;
                        return (
                          <EstimatedPayFormChip
                            key={value}
                            value={value}
                            label={`${value} ${pluralize("year", value)}`}
                            isSelected={isSelected}
                            onChange={() => {
                              field.onChange(isSelected ? null : value);
                            }}
                          />
                        );
                      })}
                  </>
                )}
              />
            </Stack>
            {isDefined(payRate.benefitsDiff) && Math.abs(payRate.benefitsDiff) > 0 && (
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Stack spacing={3}>
                  <Text
                    variant="subtitle1"
                    sx={{ fontWeight: 500 }}
                    onClick={() => {
                      benefitsInfoModalState.openModal();
                    }}
                  >
                    Include benefits package{" "}
                    <CbhIcon
                      type="info"
                      size="small"
                      sx={{ verticalAlign: "middle", margin: "2px 0px 4px 0px" }}
                    />
                  </Text>
                  <Text variant="body2" color={(theme) => theme.palette.grey[600]}>
                    Toggle off to forfeit benefits for a higher base rate
                  </Text>
                </Stack>
                <Controller
                  name="includeBenefits"
                  control={control}
                  render={({ field }) => (
                    <IosSwitch
                      aria-label="Enable benefits package"
                      data-testid="include-benefits-switch"
                      size="large"
                      color="primary"
                      checked={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                      }}
                    />
                  )}
                />
              </Stack>
            )}
          </Stack>
        </EstimatedPayFormItem>
        {(hasWeekendDiff || shiftTypeOptionsFiltered.length > 0) && (
          <EstimatedPayFormItem title="Differentials">
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="space-between">
                <Text variant="subtitle1" sx={{ fontWeight: 500 }}>
                  Shift differential
                </Text>
                {totalShiftTypePayDifferential > 0 && (
                  <Text variant="caption" color={(theme) => theme.palette.grey[600]}>
                    +{formatCentsAsUsd(totalShiftTypePayDifferential)}
                  </Text>
                )}
              </Stack>
              <Text variant="body2" color={(theme) => theme.palette.grey[600]}>
                Select shift type to view differential rate
              </Text>
              <Stack direction="row" gap={2} flexWrap="wrap" paddingTop={2} paddingBottom={6}>
                <Controller
                  name="shiftType"
                  control={control}
                  render={({ field }) => (
                    <>
                      {shiftTypeOptionsFiltered.map(({ value, label, icon }) => {
                        const isSelected = field.value === value;
                        return (
                          <EstimatedPayFormChip
                            key={value}
                            value={value}
                            icon={icon}
                            label={label}
                            isSelected={isSelected}
                            onChange={(value) => {
                              field.onChange(isSelected ? null : value);
                            }}
                          />
                        );
                      })}
                    </>
                  )}
                />
                {hasWeekendDiff && (
                  <Controller
                    name="isWeekendShift"
                    control={control}
                    render={({ field }) => (
                      <EstimatedPayFormChip
                        value={field.value ? 1 : 0}
                        label="Weekend"
                        isSelected={field.value}
                        onChange={() => {
                          field.onChange(!field.value);
                        }}
                      />
                    )}
                  />
                )}
              </Stack>
            </Stack>
          </EstimatedPayFormItem>
        )}
      </Stack>

      <BottomSheet
        modalState={benefitsInfoModalState}
        footer={
          <DialogFooter
            orientation="vertical"
            backgroundColor={(theme) => theme.palette.background.tertiary}
            onClose={() => {
              benefitsInfoModalState.closeModal();
            }}
          />
        }
      >
        <DialogContent>
          <Stack spacing={6} mt={4}>
            <Text color={(theme) => theme.palette.text.secondary}>
              Benefits packages may not be offered for Per-Diem roles
            </Text>
          </Stack>
        </DialogContent>
      </BottomSheet>
    </>
  );
}
