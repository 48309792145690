import { BottomSheet, CbhIcon } from "@clipboard-health/ui-components";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
// TODO: Use our own Button
// eslint-disable-next-line no-restricted-imports
import { Box, Button, Slider, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { formatDistanceInMiles } from "@src/appV2/lib/utils/distance";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { jobTypeOptions, shiftTypeOptions } from "@src/appV2/redesign/PlacementCandidate/constants";
import { type Worker } from "@src/appV2/Worker/api/types";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { type GetPlacementsForWorkplaceQuery } from "../types/fetchPlacements.schema";
import { getAvailableWorkerTypesToFilter } from "../utils";
import { FilterChips } from "./FilterChips";

interface FilterButtonProps {
  filter?: GetPlacementsForWorkplaceQuery["filter"];
  setFilter: (filter: GetPlacementsForWorkplaceQuery["filter"]) => void;
  worker: Worker;
  placementCandidateId: string;
  searchByWorkplaceName?: string;
}

const filterFormSchema = z.object({
  distanceInMiles: z.number().min(0).max(300).optional(),
  jobType: z.string().optional(),
  shiftTime: z.string().optional(),
  workerTypes: z.string().optional(),
});

type FilterFormData = z.infer<typeof filterFormSchema>;

export function FilterButton(props: FilterButtonProps) {
  const { filter, setFilter, worker, placementCandidateId, searchByWorkplaceName } = props;
  const filterModalState = useModalState();
  const actualWorkerTypes = getAvailableWorkerTypesToFilter(worker.licensesData);

  const { control, handleSubmit, reset } = useForm<FilterFormData>({
    resolver: zodResolver(filterFormSchema),
    defaultValues: {
      distanceInMiles: filter?.distanceInMiles,
      jobType: filter?.jobTypes,
      shiftTime: filter?.shiftTypes,
      workerTypes: filter?.workerTypes ?? actualWorkerTypes?.join(","),
    },
  });

  // Add this useEffect to reset form values when filter changes
  useEffect(() => {
    reset({
      distanceInMiles: filter?.distanceInMiles,
      jobType: filter?.jobTypes,
      shiftTime: filter?.shiftTypes,
      workerTypes: filter?.workerTypes,
    });
  }, [filter, reset]);

  function onSubmit(data: FilterFormData) {
    const workerTypeFilter = isEmpty(data.workerTypes) ? undefined : data.workerTypes;
    const filtersToSubmit = {
      ...filter,
      distanceInMiles: data.distanceInMiles,
      jobTypes: data.jobType,
      shiftTypes: data.shiftTime,
      workerTypes: workerTypeFilter,
    };
    setFilter(filtersToSubmit);
    logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_ACTIONS, {
      placementCandidateId,
      action: "filter_applied",
      filters: { ...filtersToSubmit, workplaceName: searchByWorkplaceName },
    });
    filterModalState.closeModal();
  }

  const nonEmptyFilter = Object.fromEntries(
    Object.entries(filter ?? {}).filter(([_, value]) => value)
  );
  const totalAppliedFilters = Object.keys(nonEmptyFilter).length;

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          backgroundColor: (theme) => theme.palette.background.tertiary,
          "&:hover": {
            background: (theme) => theme.palette.background.tertiary,
          },
        }}
        startIcon={
          totalAppliedFilters > 0 ? (
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                borderRadius: "50%",
                height: "1.2rem",
                width: "1.2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text variant="caption" color="white">
                {totalAppliedFilters}
              </Text>
            </Box>
          ) : (
            <CbhIcon type="slider-settings" />
          )
        }
        onClick={() => {
          filterModalState.openModal();
          logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_ACTIONS, {
            placementCandidateId,
            action: "filter_opened",
            filters: { ...filter, workplaceName: searchByWorkplaceName },
          });
        }}
      >
        <Text>Filters</Text>
      </Button>

      <BottomSheet
        disableSwipeArea
        modalState={filterModalState}
        footer={
          <DialogFooter
            orientation="horizontal"
            onClose={() => {
              filterModalState.closeModal();
            }}
          >
            <Button size="large" variant="contained" onClick={handleSubmit(onSubmit)}>
              Show Jobs
            </Button>
          </DialogFooter>
        }
      >
        <Stack sx={{ p: 7 }} spacing={8}>
          <Text semibold variant="h4">
            Filters
          </Text>

          <Stack spacing={8}>
            <Text semibold variant="body1">
              Distance from Home
            </Text>
            <Box>
              <Controller
                name="distanceInMiles"
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    value={field.value ?? 0}
                    max={300}
                    step={1}
                    marks={[
                      { value: 0, label: "0 mi" },
                      { value: 100, label: "100 mi" },
                      { value: 200, label: "200 mi" },
                      { value: 300, label: "300 mi" },
                    ]}
                    sx={{
                      "& .MuiSlider-rail": {
                        height: "2rem",
                        borderRadius: 0,
                      },
                      "& .MuiSlider-track": {
                        height: "2rem",
                        borderRadius: 0,
                      },
                      "& .MuiSlider-thumb": {
                        display: "block",
                        width: "2px",
                        height: "2.5rem",
                        borderRadius: 0,
                      },
                      "& .MuiSlider-markLabel": {
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        marginTop: 1,
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              name="distanceInMiles"
              control={control}
              render={({ field }) => (
                <Text semibold variant="body1">
                  {isDefined(field.value) && formatDistanceInMiles(field.value, "long")}
                </Text>
              )}
            />
          </Stack>

          <FilterChips
            name="jobType"
            control={control}
            options={jobTypeOptions}
            title="Job Types"
          />

          <FilterChips
            name="shiftTime"
            control={control}
            options={shiftTypeOptions}
            title="Shift Times"
          />

          {isDefined(actualWorkerTypes) && actualWorkerTypes.length > 1 && (
            <FilterChips
              name="workerTypes"
              control={control}
              options={actualWorkerTypes.map((type) => ({ label: type, value: type }))}
              title="Licenses"
            />
          )}
        </Stack>
      </BottomSheet>
    </>
  );
}
