import { Card } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";

interface EstimatedPayFormItemProps {
  title: string;
  children: React.ReactNode;
}

export function EstimatedPayFormItem({ title, children }: EstimatedPayFormItemProps) {
  return (
    <Stack spacing={2}>
      <Text variant="caption" color={(theme) => theme.palette.grey[600]} paddingLeft={3}>
        {title}
      </Text>
      <Card variant="tertiary" sx={{ paddingY: 2 }}>
        <CardContent>{children}</CardContent>
      </Card>
    </Stack>
  );
}
